import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./base-table.mod.css";

class BaseTable extends Component {
  static displayName = "BaseTable";
  static propTypes = {
    title: PropTypes.string.isRequired,
    fetchData: PropTypes.func.isRequired,
    parseData: PropTypes.func.isRequired,
    columnDefinitions: PropTypes.array.isRequired,
    allowFiltering: PropTypes.bool,
    allowPagination: PropTypes.bool,
    extraHeaderComponent: PropTypes.node,
    rowActions: PropTypes.array,
    fetchParams: PropTypes.string
  };

  static defaultProps = {
    allowFiltering: true,
    allowPagination: true
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rows: [],
      filteredRows: [],
      columnDefinitions: props.columnDefinitions,
      searchText: "",
      rowActions: props.rowActions,
      page: 0,
      rowsPerPage: 10,
      orderBy: "id",
      order: "asc",
      totalElements: 0,
      totalPages: 0
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fetchParams !== this.props.fetchParams) this.fetchData();
  }

  fetchData = () => {
    const { rowsPerPage, orderBy, order, page } = this.state;
    this.props
      .fetchData(
        this.props.fetchParams ||
          `size=${rowsPerPage}&sort=${orderBy}&${orderBy}.dir=${order}&page=${page}`
      )
      .then(data => {
        this.setState({
          rows: this.props.parseData(Array.isArray(data) ? data : data.content),
          filteredRows: this.props.parseData(
            Array.isArray(data) ? data : data.content
          ),
          searchText: "",
          isLoading: false,
          totalElements: Array.isArray(data) ? data.length : data.totalElements,
          totalPages: Array.isArray(data) ? 1 : data.totalPages
        });
      });
  };

  handleSearchTextChange = event => {
    this.setState(
      { searchText: event.target.value },
      this.handleDebouncedSearchTextChange
    );
  };

  handleDebouncedSearchTextChange = debounce(() => {
    const { searchText, rows, columnDefinitions } = this.state;
    const filteredRows =
      searchText.length > 0
        ? rows.filter(row => {
            const includesSearchText = columnDefinitions.map(column => {
              const value = row[column.field];
              const isIncluded = Boolean(
                !column.notSearchable && value.includes(searchText)
              );
              return `${isIncluded}`;
            });

            return includesSearchText.includes("true");
          })
        : this.state.rows;
    this.setState({ filteredRows });
  }, 250);

  handleChangePage = (event, page) => {
    this.setState({ page, isLoading: true }, this.fetchData);
  };

  handleChangeSorting = nextOrderBy => {
    const { orderBy, order } = this.state;
    let nextOrder = order;
    if (nextOrderBy === orderBy) {
      nextOrder = order === "asc" ? "desc" : "asc";
    }
    this.setState(
      { order: nextOrder, orderBy: nextOrderBy, isLoading: true },
      this.fetchData
    );
  };

  handleChangeRowsPerPage = (event, { key: rowsPerPage }) => {
    this.setState(
      { rowsPerPage: Number(rowsPerPage), isLoading: true },
      this.fetchData
    );
  };

  renderEmptyRows = (filteredRowsCount, rowsCount, numberOfColumns) => {
    let emptyRows = [];
    for (let i = 0; i < rowsCount - filteredRowsCount; i++) {
      emptyRows.push(
        <TableRow className="empty-rows" tabIndex={-1} key={i}>
          <TableCell colSpan={numberOfColumns} variant="body" align="center">
            <Typography variant="subtitle1" id="tableTitle">
              {filteredRowsCount === 0 &&
              Math.floor((rowsCount - filteredRowsCount) * 0.5) === i
                ? "Sin resultados"
                : ""}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return emptyRows;
  };

  render() {
    const {
      isLoading,
      rowsPerPage,
      filteredRows,
      columnDefinitions,
      rowActions,
      totalElements
    } = this.state;
    const numberOfColumns =
      rowActions.length > 0
        ? columnDefinitions.length + 1
        : columnDefinitions.length;
    if (isLoading) {
      return (
        <div className="wrapper">
          <div className="loading">
            <CircularProgress />
          </div>
        </div>
      );
    }
    return (
      <div className="table-wrapper">
        <Paper className="paper">
          <Toolbar className="table-wrapper">
            <div className="title">
              <Typography variant="h6" id="tableTitle">
                {this.props.title}
              </Typography>
            </div>
            <div className="extra-header-component">
              {this.props.extraHeaderComponent}
            </div>
            {this.props.allowFiltering && (
              <div className="base-table-search">
                <TextField
                  placeholder="Buscar en pagina"
                  id="simple-start-adornment"
                  onChange={this.handleSearchTextChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            )}
          </Toolbar>
          <div className="tableWrapper">
            <Table className="table" aria-labelledby="tableTitle" size="medium">
              <TableHead className="base-table-header">
                <TableRow>
                  {columnDefinitions.map(column => (
                    <TableCell
                      key={column.field}
                      align="left"
                      component="th"
                      sortDirection={
                        this.state.orderBy === column.field
                          ? this.state.order
                          : false
                      }
                    >
                      {column.notSortable ? (
                        column.title
                      ) : (
                        <TableSortLabel
                          active={this.state.orderBy === column.field}
                          direction={this.state.order}
                          onClick={event =>
                            this.handleChangeSorting(column.field)
                          }
                        >
                          {column.title}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                  {this.props.rowActions.length > 0 && (
                    <TableCell key="actions" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row, index) => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={index}
                      className="base-table-row"
                    >
                      {this.props.columnDefinitions.map((column, index) => (
                        <TableCell
                          align={column.align || "left"}
                          variant="body"
                          key={index}
                        >
                          {column.render
                            ? column.render(row)
                            : row[column.field]}
                        </TableCell>
                      ))}
                      {this.props.rowActions.length > 0 && (
                        <TableCell
                          align="center"
                          className="base-table-actions-cell"
                        >
                          {this.props.rowActions.map((action, index) => {
                            const ActionIcon = action.icon;
                            const showAction = action.show
                              ? action.show(row)
                              : true;
                            if (!showAction) return null;
                            return (
                              <Tooltip title={action.tooltip} key={index}>
                                <IconButton onClick={() => action.onClick(row)}>
                                  <ActionIcon />
                                </IconButton>
                              </Tooltip>
                            );
                          })}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}

                {filteredRows.length < rowsPerPage &&
                  this.renderEmptyRows(
                    filteredRows.length,
                    rowsPerPage,
                    numberOfColumns
                  )}
              </TableBody>
            </Table>
          </div>
          {this.props.allowPagination && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalElements}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage="Resultados por pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          )}
        </Paper>
      </div>
    );
  }
}

export default BaseTable;
