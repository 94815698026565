import React, { Component } from "react";
import { Container } from "reactstrap";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { getRequest } from "../utils/secure-request";
import AppNavbar from "../app-nav-bar";
import BaseTable from "../base-table";
import calcularTotalFactura from "../utils/calcular-total-factura";
import "../styles/tables.mod.css";

const PRIMER_ANYO = 2018;

const calcularTrimestre = fecha =>
  Math.ceil((new Date().getMonth() + 1) * 0.25);

const getAnyos = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = 0; i <= currentYear - PRIMER_ANYO; i = i + 1) {
    years.push(PRIMER_ANYO + i);
  }

  return years.map(year => ({ label: year, value: year }));
};

class Facturas extends Component {
  static displayName = "Facturas";
  constructor(props) {
    super(props);
    this.state = {
      anyo: new Date().getFullYear(),
      anyos: getAnyos(),
      trimestre1: [],
      trimestre2: [],
      trimestre3: []
    };
  }

  fetchData = fetchParams => getRequest(`facturas/${fetchParams}`);

  parseData = facturas =>
    facturas.map(factura => ({
      id: factura.id,
      trimestre: calcularTrimestre(factura.fecha),
      numero: factura.numero,
      fecha: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(new Date(factura.fecha)),
      contacto: `${factura.apellido1}, ${factura.nombre}`,
      telefono1: factura.telefono1,
      telefono2: factura.telefono2,
      dias: factura.dias,
      precioDiaCentimos: `${(factura.precioDiaCentimos * 0.01).toFixed(2)}€`,
      porcentajeIva: `${factura.porcentajeIva}%`,
      servicios: factura.servicios.map(ser => ser.servicio),
      animales: factura.animales,
      total: `${calcularTotalFactura(factura).toFixed(2)}€`
    }));

  handleOpenFactura(facturaId) {
    const win = window.open(`/factura/${facturaId}`, "_blank");
    win.focus();
  }

  render() {
    const { anyo, anyos } = this.state;
    console.log({ anyo });
    return (
      <div className="wrapper">
        <AppNavbar />
        <Container fluid>
          <BaseTable
            title="Facturas"
            fetchData={this.fetchData}
            parseData={this.parseData}
            fetchParams={`${this.state.anyo}`}
            columnDefinitions={[
              {
                title: "Trimestre",
                field: "trimestre"
              },
              {
                title: "Numero",
                field: "numero",
                searchable: false
              },
              {
                title: "Fecha",
                field: "fecha"
              },
              {
                title: "Contacto",
                field: "contacto"
              },
              {
                title: "Telefono1",
                field: "telefono1"
              },
              {
                title: "Telefono2",
                field: "telefono2"
              },
              { title: "Dias", field: "dias" },
              {
                title: "Precio",
                field: "precioDiaCentimos",
                searchable: false
              },
              {
                title: "IVA",
                field: "porcentajeIva",
                searchable: false
              },
              {
                title: "Servicios",
                field: "servicios",
                render: rowData =>
                  rowData.servicios.map((servicio, index) => (
                    <p key={index}>{servicio}</p>
                  ))
              },
              {
                title: "Animales",
                field: "animales"
              },
              {
                title: "Total",
                field: "total"
              }
            ]}
            rowActions={[
              {
                icon: InsertDriveFileIcon,
                tooltip: "Factura",
                onClick: row => this.handleOpenFactura(row.id)
              }
            ]}
            extraHeaderComponent={
              <TextField
                id="anyo-facturacion"
                select
                value={anyo}
                onChange={event =>
                  this.setState({
                    anyo: event.target.value
                  })
                }
                margin="normal"
              >
                {anyos.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
            allowPagination={false}
          />
        </Container>
      </div>
    );
  }
}

export default Facturas;
