import { BehaviorSubject } from "rxjs";

import { handleResponse } from "./handle-response";

const API_URL =
  process.env.NODE_ENV === "production" ? "https://protectoramodepran.com" : "";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  register,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function register(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };
  return fetch(`${API_URL}/hotel/register`, requestOptions)
    .then(handleResponse)
    .then(response => {
      login(username, password);
    })
    .catch(error => Promise.reject(error));
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };
  console.log("requestOptions", requestOptions);
  return fetch(`${API_URL}/hotel/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    })
    .catch(error => Promise.reject(error));
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
