const calcularTotalFactura = factura => {
  const count = Array.isArray(factura.animales)
    ? factura.animales.length
    : factura.animales.split(",").length;
  let total = factura.precioDiaCentimos * factura.dias * count * 0.01;
  const servicios = factura.servicios.reduce(
    (subtotal, servicio) => subtotal + servicio.precio * 0.01,
    0
  );

  return total + servicios;
};

export default calcularTotalFactura;
