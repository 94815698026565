import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import { postRequest } from "../utils/secure-request";
import AnimalForm from "../animal-form";
import AppNavbar from "../app-nav-bar";
import "../styles/app.css";
import "../styles/form.mod.css";

export const emptyAnimal = {
  nombre: "",
  especie: "Perro",
  tamanyo: "",
  sexo: "",
  chip: "",
  fechaNacimiento: "",
  idContacto: null
};

const validate = values => {
  const errors = {};
  if (values.nombre.length === 0) errors.nombre = true;
  if (values.especie.length === 0) errors.especie = true;
  if (values.tamanyo.length === 0) errors.tamanyo = true;
  if (values.sexo.length === 0) errors.sexo = true;
  if (values.fechaNacimiento.length === 0) errors.fechaNacimiento = true;
  return errors;
};

class AnimalNew extends Component {
  static displayName = "AnimalNew";
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    postRequest("animal", JSON.stringify(values))
      .then(data => document.location.replace(`/animal/${data.id}`))
      .catch(error => window.alert(JSON.stringify(error)));
  }

  handleCancel = () => {
    document.location.replace(`/animales`);
  };

  render() {
    return (
      <div className="app">
        <AppNavbar />
        <div className="form-wrapper">
          <Formik
            initialValues={emptyAnimal}
            onSubmit={this.handleSubmit}
            validate={values => validate(values)}
          >
            {formikProps => {
              return (
                <Card>
                  <div className="form-title">
                    <CardHeader title="Nuevo Animal" />
                  </div>
                  <AnimalForm
                    values={formikProps.values}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    touched={formikProps.touched}
                    errors={formikProps.errors}
                  />
                  <CardActions>
                    <div className="inline-row">
                      <Button
                        disabled={formikProps.isSubmitting}
                        onClick={this.handleCancel}
                        size="small"
                      >
                        Atras
                      </Button>
                      <div className="actions-row">
                        <Button
                          disabled={formikProps.isSubmitting}
                          onClick={formikProps.handleReset}
                          size="small"
                        >
                          Cancelar
                        </Button>
                        <Button
                          disabled={
                            formikProps.isSubmitting || !formikProps.dirty
                          }
                          onClick={formikProps.handleSubmit}
                          size="small"
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </CardActions>
                </Card>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default AnimalNew;
