import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getRequest, putRequest } from "../utils/secure-request";
import EstadiaForm from "../estadia-form";
import AppNavbar from "../app-nav-bar";
import ReservaContactoSubform from "../reserva-contacto-subform";
import ServiciosSubform from "../servicios-subform";
import "../styles/app.css";
import "../styles/form.mod.css";
import "./estadia-edit.mod.css";

const HORA_REGEX = /^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$/;

const validate = values => {
  const errors = {};
  if (values.fechaEntrada.length === 0) errors.fechaEntrada = true;
  if (values.fechaSalida.length === 0) errors.fechaSalida = true;
  if (values.horaEntrada.length === 0) errors.horaEntrada = true;
  if (!values.horaEntrada.match(HORA_REGEX)) errors.horaEntrada = true;
  if (values.horaSalida.length === 0) errors.horaSalida = true;
  if (!values.horaSalida.match(HORA_REGEX)) errors.horaSalida = true;
  if (values.precioDia.length === 0) errors.precioDia = true;
  if (values.porcentajeIva.length === 0) errors.precioDia = true;
  if (!values.contacto) errors.contacto = true;
  if (!values.animales || values.animales.length === 0) errors.animales = true;

  return errors;
};

class EstadiaEdit extends Component {
  static displayName = "EstadiaEdit";
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      isSuccessOpen: false,
      estadiaId: props.match.params.id,
      isLoading: true
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const estadia = await getRequest(`estadia/${this.props.match.params.id}`);

    const fechaEntrada = new Date(estadia.fechaEntrada);
    const fechaSalida = new Date(estadia.fechaSalida);
    this.setState({
      isLoading: false,
      estadia: {
        ...estadia,
        fechaEntrada: fechaEntrada.toISOString().substring(0, 10),
        fechaSalida: fechaSalida.toISOString().substring(0, 10),
        precioDia: `${estadia.precioDiaCentimos * 0.01}`
      }
    });
  }

  handleSubmit(values, addHandlers) {
    addHandlers(
      putRequest(`estadia/${this.state.estadiaId}`, JSON.stringify(values)),
      this.setState({ isSuccessOpen: true })
    );
  }

  handleCancel = () => {
    document.location.replace(`/estadias`);
  };

  render() {
    const hasFactura = this.state.estadia && this.state.estadia.factura;
    return (
      <div className="app">
        <AppNavbar />
        <div className="form-wrapper">
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={this.state.estadia}
              onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                  promise.then(
                    result => {
                      formikBag.setSubmitting(false);
                      return result;
                    },
                    error => {
                      formikBag.setSubmitting(false);
                      throw error;
                    }
                  );

                const parsedValues = {
                  ...values,
                  precioDiaCentimos: values.precioDia * 100
                };

                return this.handleSubmit(parsedValues, addHandlers);
              }}
              validate={values => validate(values)}
            >
              {formikProps => (
                <Card>
                  <div className="form-title">
                    <CardHeader title="Editar Estadia" />
                    {hasFactura && (
                      <span className="has-factura-warning">
                        No se puede editar: Ya se ha emitido{" "}
                        <a
                          className="factura-warning-link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`/factura/${this.state.estadia.factura.id}`}
                        >
                          factura
                        </a>
                      </span>
                    )}
                  </div>
                  {this.state.estadia.reserva && (
                    <CardContent className="card-content">
                      <div className="form-line">
                        <TextField
                          type="number"
                          disabled
                          label="Reserva"
                          value={this.state.estadia.id}
                        />
                      </div>
                    </CardContent>
                  )}
                  <EstadiaForm
                    values={formikProps.values}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    touched={formikProps.touched}
                    errors={formikProps.errors}
                  />
                  <ReservaContactoSubform formikProps={formikProps} />
                  <ServiciosSubform formikProps={formikProps} />

                  <CardActions>
                    <div className="inline-row">
                      <Button
                        disabled={formikProps.isSubmitting}
                        onClick={this.handleCancel}
                        size="small"
                      >
                        Atras
                      </Button>
                      <div className="actions-row">
                        <Button
                          disabled={formikProps.isSubmitting}
                          onClick={formikProps.handleReset}
                          size="small"
                        >
                          Cancelar
                        </Button>
                        <Button
                          disabled={formikProps.isSubmitting || hasFactura}
                          onClick={formikProps.handleSubmit}
                          size="small"
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </CardActions>
                  <Snackbar
                    className="success-message"
                    aria-describedby="success-message"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={this.state.isSuccessOpen}
                    autoHideDuration={5000}
                    ContentProps={{
                      "aria-describedby": "message-id"
                    }}
                    onClose={() => this.setState({ isSuccessOpen: false })}
                    message={
                      <span id="success-message" className="message">
                        <CheckCircleIcon className="icon" /> Cambios guardados
                      </span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Cerrar"
                        color="inherit"
                        onClick={() => this.setState({ isSuccessOpen: false })}
                      >
                        <CloseIcon />
                      </IconButton>
                    ]}
                  />
                </Card>
              )}
            </Formik>
          )}
        </div>
      </div>
    );
  }
}

export default EstadiaEdit;
