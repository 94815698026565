import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CardContent from "@material-ui/core/CardContent";
import "../styles/form.mod.css";

class ReservaForm extends Component {
  static displayName = "ReservaForm";

  static propTypes = {
    values: PropTypes.shape({
      fechaEntrada: PropTypes.string,
      fechaSalida: PropTypes.string,
      horaEntrada: PropTypes.string,
      horaSalida: PropTypes.string,
      precioDia: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };

  render() {
    const { values, handleChange, handleBlur, touched, errors } = this.props;
    return (
      <CardContent className="card-content">
        <div className="form-line">
          <TextField
            required
            error={touched.fechaEntrada && errors.fechaEntrada}
            name="fechaEntrada"
            label="Fecha de entrada"
            type="date"
            defaultValue={values.fechaEntrada}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            required
            error={touched.fechaSalida && errors.fechaSalida}
            name="fechaSalida"
            label="Fecha de salida"
            type="date"
            defaultValue={values.fechaSalida}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="form-line">
          <TextField
            required
            label="Hora de entrada"
            margin="normal"
            name="horaEntrada"
            value={values.horaEntrada}
            error={touched.horaEntrada && errors.horaEntrada}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            required
            label="Hora de salida"
            margin="normal"
            name="horaSalida"
            value={values.horaSalida}
            error={touched.horaSalida && errors.horaSalida}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="hh:mm"
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        <div className="form-line">
          <TextField
            required
            error={touched.precioDia && errors.precioDia}
            name="precioDia"
            label="Precio por dia"
            type="number"
            value={values.precioDia}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              )
            }}
          />
        </div>
      </CardContent>
    );
  }
}

export default ReservaForm;
