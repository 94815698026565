import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { getRequest, putRequest } from "../utils/secure-request";
import ContactoSearch from "../contacto-search";
import AnimalForm from "../animal-form";
import AppNavbar from "../app-nav-bar";
import "../styles/app.css";
import "../styles/form.mod.css";

const validate = values => {
  const errors = {};
  if (values.nombre.length === 0) errors.nombre = true;
  if (values.especie.length === 0) errors.especie = true;
  if (values.tamanyo.length === 0) errors.tamanyo = true;
  if (values.sexo.length === 0) errors.sexo = true;
  if (values.fechaNacimiento.length === 0) errors.fechaNacimiento = true;
  return errors;
};

class AnimalEdit extends Component {
  static displayName = "AnimalEdit";
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      animalId: props.match.params.id,
      animal: null,
      isLoading: true,
      contactos: [],
      searchBarReference: React.createRef(),
      popperReference: null,
      isNoContactosOpen: false,
      isSuccessOpen: false
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const animal = await getRequest(`animal/${this.props.match.params.id}`);

    const fnac = new Date(animal.fechaNacimiento);
    this.setState({
      isLoading: false,
      animal: {
        ...animal,
        fechaNacimiento: fnac.toISOString().substring(0, 10),
        chip: animal.chip || ""
      },
      contactoSearch: ""
    });
  }

  handleSubmit(values, addHandlers) {
    addHandlers(
      putRequest(`animal/${this.state.animalId}`, JSON.stringify(values)),
      this.setState({ isSuccessOpen: true })
    );
  }

  handleCancel = () => {
    document.location.replace(`/animales`);
  };

  render() {
    return (
      <div className="app">
        <AppNavbar />
        <div className="form-wrapper">
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={this.state.animal}
              onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                  promise.then(
                    result => {
                      formikBag.setSubmitting(false);
                      return result;
                    },
                    error => {
                      formikBag.setSubmitting(false);
                      throw error;
                    }
                  );

                return this.handleSubmit(values, addHandlers);
              }}
              validate={values => validate(values)}
            >
              {formikProps => (
                <Card>
                  <div className="form-title">
                    <CardHeader title="Editar Animal" />
                  </div>
                  <AnimalForm
                    values={formikProps.values}
                    handleChange={formikProps.handleChange}
                    handleBlur={formikProps.handleBlur}
                    touched={formikProps.touched}
                    errors={formikProps.errors}
                  />
                  <FieldArray
                    name="contactos"
                    render={({ push, remove, form }) => (
                      <CardContent>
                        {formikProps.values.contactos.map((contacto, index) => (
                          <div
                            key={`contacto-${index}`}
                            className="contacto-form-line"
                          >
                            <TextField
                              disabled
                              className="search"
                              label={`Contacto ${index + 1}`}
                              value={`${contacto.nombre} ${contacto.apellido1} ${contacto.apellido2}`}
                              fullWidth
                            />
                            <IconButton
                              onClick={() => remove(index)}
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        ))}
                        <ContactoSearch onAddContacto={push} />
                      </CardContent>
                    )}
                  />
                  <CardActions>
                    <div className="inline-row">
                      <Button
                        disabled={formikProps.isSubmitting}
                        onClick={this.handleCancel}
                        size="small"
                      >
                        Atras
                      </Button>
                      <div className="actions-row">
                        <Button
                          disabled={formikProps.isSubmitting}
                          onClick={formikProps.handleReset}
                          size="small"
                        >
                          Cancelar
                        </Button>
                        <Button
                          disabled={
                            formikProps.isSubmitting || !formikProps.dirty
                          }
                          onClick={formikProps.handleSubmit}
                          size="small"
                        >
                          Guardar
                        </Button>
                      </div>
                    </div>
                  </CardActions>
                </Card>
              )}
            </Formik>
          )}
        </div>
        <Snackbar
          className="success-message"
          aria-describedby="success-message"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.isSuccessOpen}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          onClose={() => this.setState({ isSuccessOpen: false })}
          message={
            <span id="success-message" className="message">
              <CheckCircleIcon className="icon" /> Cambios guardados
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Cerrar"
              color="inherit"
              onClick={() => this.setState({ isSuccessOpen: false })}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default AnimalEdit;
