import React, { Component } from "react";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ServicioTransporteSubformModal from "../servicio-transporte-subform-modal";
import OtroServicioSubformModal from "../otro-servicio-subform-modal";
import "../styles/form.mod.css";

class ServiciosSubform extends Component {
  static displayName = "ServiciosSubform";
  static propTypes = {
    formikProps: PropTypes.shape({
      values: PropTypes.shape({
        recogida: PropTypes.object,
        entrega: PropTypes.object,
        servicios: PropTypes.array
      }),
      setFieldValue: PropTypes.func.isRequired,
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      isServicioModalOpen: false,
      hasRecogida: this.props.formikProps.values.recogida,
      isRecogidaOpen: false,
      hasEntrega: this.props.formikProps.values.entrega,
      isEntregaOpen: false,
      isServicioOpen: false
    };
  }

  openRecogidaModal = () => {
    this.setState({
      isRecogidaOpen: true
    });
  };

  closeRecogidaModal = () => {
    this.setState({
      isRecogidaOpen: false,
      hasRecogida: this.props.formikProps.values.recogida ? true : false
    });
  };

  openEntregaModal = () => {
    this.setState({
      isEntregaOpen: true
    });
  };

  openServicioModal = () => {
    this.setState({
      isServicioOpen: true
    });
  };

  closeEntregaModal = () => {
    this.setState({
      isEntregaOpen: false,
      hasEntrega: this.props.formikProps.values.entrega ? true : false
    });
  };

  closeServicioModal = () => {
    this.setState({
      isServicioOpen: false
    });
  };

  handleSaveRecogida = recogida => {
    this.props.formikProps.setFieldValue("recogida", recogida);
    this.closeRecogidaModal();
  };

  handleSaveEntrega = entrega => {
    this.props.formikProps.setFieldValue("entrega", entrega);
    this.closeEntregaModal();
  };

  handleSaveServicio = servicio => {
    const { servicios = [] } = this.props.formikProps.values;

    this.props.formikProps.setFieldValue("servicios", [...servicios, servicio]);
    this.closeServicioModal();
  };

  render() {
    const { values } = this.props.formikProps;
    const {
      hasRecogida,
      hasEntrega,
      isRecogidaOpen,
      isEntregaOpen,
      isServicioOpen
    } = this.state;
    return (
      <CardContent className="card-content">
        <div className="actions-row">
          {!hasRecogida && (
            <Fab
              size="small"
              className="servicio-button"
              variant="extended"
              aria-label="Recogida"
              onClick={this.openRecogidaModal}
            >
              <AddIcon />
              Recogida
            </Fab>
          )}
          {!hasEntrega && (
            <Fab
              size="small"
              className="servicio-button"
              variant="extended"
              aria-label="Entrega"
              onClick={this.openEntregaModal}
            >
              <AddIcon />
              Entrega
            </Fab>
          )}
          <Fab
            size="small"
            className="servicio-button"
            variant="extended"
            aria-label="Servicio"
            onClick={this.openServicioModal}
          >
            <AddIcon />
            Servicio
          </Fab>
          {isRecogidaOpen && (
            <ServicioTransporteSubformModal
              title={"Recogida"}
              autocomplete={{
                fecha: values.fechaEntrada,
                hora: values.horaEntrada,
                direccion1: values.contacto.direccion1,
                direccion2: values.contacto.direccion2,
                direccion3: values.contacto.direccion3
              }}
              onSave={this.handleSaveRecogida}
              onClose={this.closeRecogidaModal}
            />
          )}
          {isEntregaOpen && (
            <ServicioTransporteSubformModal
              title={"Entrega"}
              autocomplete={{
                fecha: values.fechaSalida,
                hora: values.horaSalida,
                direccion1: values.contacto.direccion1,
                direccion2: values.contacto.direccion2,
                direccion3: values.contacto.direccion3
              }}
              onSave={this.handleSaveEntrega}
              onClose={this.closeEntregaModal}
            />
          )}
          {isServicioOpen && (
            <OtroServicioSubformModal
              onSave={this.handleSaveServicio}
              onClose={this.closeServicioModal}
            />
          )}
        </div>
        {values.recogida && (
          <div className="contacto-form-line">
            <TextField
              disabled
              className="search"
              label={"Recogida"}
              value={`${new Intl.DateTimeFormat("es-ES", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              }).format(new Date(values.recogida.fecha))}, ${
                values.recogida.hora
              }`}
              fullWidth
            />
            <IconButton
              onClick={() => {
                this.props.formikProps.setFieldValue("recogida", null);
                this.setState({ hasRecogida: false });
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        {values.entrega && (
          <div className="contacto-form-line">
            <TextField
              disabled
              className="search"
              label={"Entrega"}
              value={`${new Intl.DateTimeFormat("es-ES", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              }).format(new Date(values.entrega.fecha))}, ${
                values.entrega.hora
              }`}
              fullWidth
            />
            <IconButton
              onClick={() => {
                this.props.formikProps.setFieldValue("entrega", null);
                this.setState({ hasEntrega: false });
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        {values.servicios &&
          values.servicios.map(({ servicio }, index) => (
            <div key={index} className="contacto-form-line">
              <TextField
                disabled
                className="search"
                label={index === 0 ? "Servicios" : ""}
                value={servicio}
                fullWidth
              />
              <IconButton
                onClick={() => {
                  this.props.formikProps.setFieldValue(
                    "servicios",
                    values.servicios.filter(
                      (servicio, subindex) => index !== subindex
                    )
                  );
                }}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
      </CardContent>
    );
  }
}

export default ServiciosSubform;
