import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import "../styles/modal.mod.css";

const emptyServicio = {
  precio: "",
  servicio: "",
  observaciones: ""
};

const validate = values => {
  const errors = {};
  if (values.servicio.length === 0) errors.servicio = true;
  if (values.precio.length === 0) errors.precio = true;

  return errors;
};

class OtroServicioSubformModal extends Component {
  static displayName = "OtroServicioSubformModal";
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    return (
      <Formik
        initialValues={emptyServicio}
        onSubmit={this.props.onSave}
        validate={values => validate(values)}
      >
        {formikProps => (
          <Modal
            aria-labelledby="simple-modal-title"
            open={true}
            onClose={this.props.onClose}
          >
            <div className="custom-modal">
              <Card>
                <h2 className="custom-modal-title">Servicio</h2>
                <div className="contacto-form-line">
                  <TextField
                    name="servicio"
                    label="Servicio"
                    value={formikProps.values.servicio}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="contacto-form-line">
                  <TextField
                    name="observaciones"
                    label="Observaciones"
                    value={formikProps.values.observaciones}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                    multiline
                    rowsMax="4"
                  />
                </div>
                <div className="form-line">
                  <TextField
                    required
                    error={
                      formikProps.touched.precio && formikProps.errors.precio
                    }
                    name="precio"
                    label="Precio"
                    type="number"
                    value={formikProps.values.precio}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                </div>
                <CardActions>
                  <div className="actions-row">
                    <Button onClick={this.props.onClose} size="small">
                      Cancelar
                    </Button>
                    <Button onClick={formikProps.handleSubmit} size="small">
                      Guardar
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default OtroServicioSubformModal;
