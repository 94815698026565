import React, { Component } from "react";
import { Container } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import PortraitIcon from "@material-ui/icons/Portrait";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import HotelIcon from "@material-ui/icons/Hotel";
import WeekendIcon from "@material-ui/icons/Weekend";
import EditIcon from "@material-ui/icons/Edit";
import AppNavbar from "../app-nav-bar";
import BaseTable from "../base-table";
import CustomDialog from "../custom-dialog";
import CustomSnackbar from "../custom-snackbar";
import { getRequest, deleteRequest } from "../utils/secure-request";
import "../styles/tables.mod.css";

class Reservas extends Component {
  static displayName = "Reservas";
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      removeId: null
    };
    this.remove = this.remove.bind(this);
  }

  fetchData = paramsStr => getRequest(`reservas?${paramsStr}`);

  async remove() {
    const { removeId } = this.state;
    const response = await deleteRequest(`reserva/${removeId}`);
    if (response.ok) {
      this.setState({ removeId: null }, document.location.replace(`/reservas`));
    } else {
      this.setState({ removeId: null, showSnackbar: true });
    }
  }

  parseData = reservas =>
    reservas.map(reserva => ({
      id: reserva.id,
      fechaEntrada: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(new Date(reserva.fechaEntrada)),
      horaEntrada: reserva.horaEntrada,
      fechaSalida: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(new Date(reserva.fechaSalida)),
      horaSalida: reserva.horaSalida,
      contacto: `${reserva.contacto.apellido1}, ${reserva.contacto.nombre}`,
      contactoId: reserva.contacto.id,
      telefono1: reserva.contacto.telefono1,
      telefono2: reserva.contacto.telefono2,
      precioDiaCentimos: `${(reserva.precioDiaCentimos * 0.01).toFixed(2)}€`,
      animales: reserva.animales.map(
        animal => `${animal.nombre} (${animal.especie}, ${animal.sexo})`
      ),
      animalesIds: reserva.contacto.animales.map(animal => animal.id),
      estadia: reserva.estadia
    }));

  handleGoToEstadiaClick = estadiaId =>
    document.location.replace(`/estadia/${estadiaId}`);

  handleDeleteClick = removeId =>
    this.setState({ removeId, isDialogOpen: true });

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      removeId: null
    });
  };

  handleDialogAccept = () => {
    this.setState(
      {
        isDialogOpen: false
      },
      this.remove
    );
  };

  render() {
    return (
      <div className="wrapper">
        <AppNavbar />
        <Container fluid>
          <div className="add-button-wrapper">
            <Fab
              title="Nueva reserva"
              href="/reservas/new"
              className="add-button"
            >
              <AddIcon />
            </Fab>
          </div>
          <BaseTable
            title="Reservas"
            fetchData={this.fetchData}
            parseData={this.parseData}
            columnDefinitions={[
              {
                title: "Entrada",
                field: "fechaEntrada",
                notSearchable: true
              },
              { title: "Hora", field: "horaEntrada" },
              {
                title: "Salida",
                field: "fechaSalida",
                notSearchable: true
              },
              { title: "Hora", field: "horaSalida" },
              {
                title: "Precio",
                field: "precioDiaCentimos",
                notSearchable: true
              },
              {
                title: "Contacto",
                field: "contacto",
                notSortable: true,
                render: rowData => (
                  <Button
                    title="Contacto"
                    href={`/contacto/${rowData.contactoId}`}
                    className="contact-button"
                  >
                    <PortraitIcon />
                  </Button>
                )
              },
              {
                title: "Telefono1",
                field: "telefono1"
              },
              {
                title: "Telefono2",
                field: "telefono2"
              },
              {
                title: "Animales",
                field: "animales",
                notSortable: true,
                render: rowData =>
                  rowData.animales.map((animal, index) => (
                    <p key={index}>
                      <a href={`/animal/${rowData.animalesIds[index]}`}>
                        {animal}
                      </a>
                    </p>
                  ))
              }
            ]}
            rowActions={[
              {
                icon: EditIcon,
                tooltip: "Editar reserva",
                onClick: row => document.location.replace(`/reserva/${row.id}`),
                show: row => !row.estadia
              },
              {
                icon: WeekendIcon,
                tooltip: "Generar estadia",
                onClick: row =>
                  document.location.replace(`/estadias/new/${row.id}`),
                show: row => !row.estadia
              },
              {
                icon: DeleteIcon,
                tooltip: "Eliminar reserva",
                onClick: row => this.handleDeleteClick(row.id),
                show: row => !row.estadia
              },
              {
                icon: HotelIcon,
                tooltip: "Ir a estadia",
                onClick: row => this.handleGoToEstadiaClick(row.estadia.id),
                show: row => row.estadia
              }
            ]}
          />
          <CustomDialog
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            onAccept={this.handleDialogAccept}
            message="Se va a eliminar la reserva. Esta acción no puede deshacerse. Confirmar acción?"
          />
          {this.state.showSnackbar && (
            <CustomSnackbar
              variant="error"
              message="No se pudo eliminar la reserva"
            />
          )}
        </Container>
      </div>
    );
  }
}

export default Reservas;
