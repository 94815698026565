import { authenticationService } from "./authentication-service";

export function handleResponse(response) {
  console.log({ response });
  if (response.ok) {
    return response
      .text()
      .then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            authenticationService.logout();
            document.location.reload(true);
          }

          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        return data;
      })
      .catch(error => Promise.reject(error));
  } else {
    authenticationService.logout();
    return Promise.reject(response.statusText);
  }
}
