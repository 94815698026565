import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import "../styles/modal.mod.css";

const HORA_REGEX = /^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$/;

const emptyTransporte = {
  hora: "",
  precio: "",
  direccion1: "",
  direccion2: "",
  direccion3: "",
  observaciones: ""
};

const validate = values => {
  const errors = {};
  if (values.fecha.length === 0) errors.fecha = true;
  if (values.hora.length === 0) errors.hora = true;
  if (!values.hora.match(HORA_REGEX)) errors.hora = true;
  if (values.precio.length === 0) errors.precio = true;

  return errors;
};

class ServicioTransporteSubformModal extends Component {
  static displayName = "ServicioTransporteSubformModal";
  static propTypes = {
    title: PropTypes.string.isRequired,
    autocomplete: PropTypes.shape({
      fecha: PropTypes.string,
      hora: PropTypes.string,
      direccion1: PropTypes.string,
      direccion2: PropTypes.string,
      direccion3: PropTypes.string
    }),
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  autocompleteForm = formikProps => {
    const { autocomplete } = this.props;
    formikProps.setFieldValue("hora", autocomplete.hora);
    formikProps.setFieldValue("direccion1", autocomplete.direccion1);
    formikProps.setFieldValue("direccion2", autocomplete.direccion2);
    formikProps.setFieldValue("direccion3", autocomplete.direccion3);
  };

  render() {
    return (
      <Formik
        initialValues={{
          ...emptyTransporte,
          fecha: this.props.autocomplete.fecha
        }}
        onSubmit={this.props.onSave}
        validate={values => validate(values)}
      >
        {formikProps => (
          <Modal
            aria-labelledby="simple-modal-title"
            open={true}
            onClose={this.props.onClose}
          >
            <div className="custom-modal">
              <Card>
                <h2 className="custom-modal-title">{this.props.title}</h2>
                <div className="form-line">
                  <TextField
                    required
                    error={
                      formikProps.touched.fecha && formikProps.errors.fecha
                    }
                    name="fecha"
                    label="Fecha"
                    type="date"
                    defaultValue={formikProps.values.fecha}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    required
                    label="Hora"
                    margin="normal"
                    name="hora"
                    value={formikProps.values.hora}
                    error={formikProps.touched.hora && formikProps.errors.hora}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    placeholder="hh:mm"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="form-line">
                  <TextField
                    required
                    error={
                      formikProps.touched.precio && formikProps.errors.precio
                    }
                    name="precio"
                    label="Precio"
                    type="number"
                    value={formikProps.values.precio}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="contacto-form-line">
                  <TextField
                    required
                    error={
                      formikProps.touched.direccion1 &&
                      formikProps.errors.direccion1
                    }
                    name="direccion1"
                    label="Direccion (linea 1)"
                    value={formikProps.values.direccion1}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="contacto-form-line">
                  <TextField
                    name="direccion2"
                    label="Direccion (linea 2)"
                    value={formikProps.values.direccion2}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="contacto-form-line">
                  <TextField
                    name="direccion3"
                    label="Direccion (linea 3)"
                    value={formikProps.values.direccion3}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                  />
                </div>
                <div className="contacto-form-line">
                  <TextField
                    name="observaciones"
                    label="Observaciones"
                    value={formikProps.values.observaciones}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    margin="normal"
                    fullWidth
                    multiline
                    rowsMax="4"
                  />
                </div>
                <CardActions>
                  <div className="inline-row">
                    <Button
                      color="primary"
                      onClick={() => this.autocompleteForm(formikProps)}
                      size="small"
                    >
                      Autocompletar
                    </Button>
                    <div className="actions-row">
                      <Button onClick={this.props.onClose} size="small">
                        Cancelar
                      </Button>
                      <Button onClick={formikProps.handleSubmit} size="small">
                        Guardar
                      </Button>
                    </div>
                  </div>
                </CardActions>
              </Card>
            </div>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default ServicioTransporteSubformModal;
