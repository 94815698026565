import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import "../styles/form.mod.css";

class ContactoForm extends Component {
  static displayName = "ContactoForm";

  static propTypes = {
    values: PropTypes.shape({
      nombre: PropTypes.string,
      apellido1: PropTypes.string,
      apellido2: PropTypes.string,
      telefono1: PropTypes.string,
      telefono2: PropTypes.string,
      email: PropTypes.string,
      nif: PropTypes.string,
      direccion1: PropTypes.string,
      direccion2: PropTypes.string,
      direccion3: PropTypes.string,
      animales: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          nombre: PropTypes.string
        })
      )
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };

  render() {
    const { values, handleChange, handleBlur, touched, errors } = this.props;
    return (
      <CardContent className="card-content">
        <div className="form-line">
          <TextField
            required
            error={touched.nif && errors.nif}
            name="nif"
            label="NIF"
            value={values.nif}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
          <TextField
            required
            error={touched.nombre && errors.nombre}
            name="nombre"
            label="Nombre"
            value={values.nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
        </div>
        <div className="form-line">
          <TextField
            required
            error={touched.apellido1 && errors.apellido1}
            name="apellido1"
            label="Apellido1"
            value={values.apellido1}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
          <TextField
            error={touched.apellido2 && errors.apellido2}
            name="apellido2"
            label="Apellido2"
            value={values.apellido2}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
        </div>
        <div className="form-line">
          <TextField
            required
            error={touched.telefono1 && errors.telefono1}
            name="telefono1"
            label="Telefono1"
            value={values.telefono1}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
          <TextField
            error={touched.telefono2 && errors.telefono2}
            name="telefono2"
            label="Telefono2"
            value={values.telefono2}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
        </div>
        <div className="form-line">
          <TextField
            required
            error={touched.direccion1 && errors.direccion1}
            name="direccion1"
            label="Direccion (linea 1)"
            value={values.direccion1}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            className="complete-line"
          />
        </div>
        <div className="form-line">
          <TextField
            error={touched.direccion2 && errors.direccion2}
            name="direccion2"
            label="Direccion (linea 2)"
            value={values.direccion2}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            className="complete-line"
          />
        </div>
        <div className="form-line">
          <TextField
            error={touched.direccion3 && errors.direccion3}
            name="direccion3"
            label="Direccion (linea 3)"
            value={values.direccion3}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            className="complete-line"
          />
        </div>
      </CardContent>
    );
  }
}

export default ContactoForm;
