import React, { Component } from "react";
import { Formik } from "formik";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { authenticationService } from "../utils/authentication-service";
import "../styles/app.css";
import "../styles/login.mod.css";
import "../styles/form.mod.css";

const rootLocation = {
  from: { pathname: "/" }
};

class Login extends Component {
  static displayName = "Login";

  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      props.history.push(rootLocation);
      // document.location.replace("/");
    }
  }

  render() {
    return (
      <div className="login-container">
        <div className="contentback">
          <div>
            <img
              src="img/logoblancolargo.png"
              className="logo"
              alt="modepran-logo"
            ></img>
          </div>
          <div className="formcontent">
            <Formik
              initialValues={{ username: "", password: "" }}
              onSubmit={(
                { username, password },
                { setStatus, setSubmitting }
              ) => {
                setStatus();
                authenticationService.login(username, password).then(
                  user => {
                    const { from } =
                      this.props.location && this.props.location.state
                        ? this.props.location.state
                        : rootLocation;
                    this.props.history.push(from);
                  },
                  error => {
                    setSubmitting(false);
                    setStatus(error);
                  }
                );
              }}
            >
              {formikProps => (
                <Card>
                  <div className="form-title">
                    <CardHeader title="Residencia" />
                  </div>
                  <CardContent className="card-content">
                    <div className="form-line">
                      <TextField
                        name="username"
                        required
                        label="Usuario"
                        value={formikProps.values.username}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                    </div>
                    <div className="form-line">
                      <TextField
                        name="password"
                        type="password"
                        required
                        label="Password"
                        value={formikProps.values.password}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                      />
                    </div>
                  </CardContent>
                  <CardActions>
                    <div className="login-button">
                      <Button
                        disabled={formikProps.isSubmitting}
                        onClick={formikProps.handleSubmit}
                        size="small"
                      >
                        Entrar
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              )}
            </Formik>
          </div>
        </div>
        <div className="sky">
          <div className="clouds-one"></div>
          <div className="clouds-two"></div>
          <div className="clouds-three"></div>
        </div>
      </div>
    );
  }
}

export default Login;
