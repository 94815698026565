import React, { Component } from "react";
import { Container } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { getRequest, deleteRequest } from "../utils/secure-request";
import AppNavbar from "../app-nav-bar";
import BaseTable from "../base-table";
import CustomDialog from "../custom-dialog";
import CustomSnackbar from "../custom-snackbar";
import "../styles/tables.mod.css";

class Contactos extends Component {
  static displayName = "Contactos";
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      removeId: null,
      showSnackbar: false
    };
    this.remove = this.remove.bind(this);
  }

  fetchData = paramsStr => getRequest(`contactos?${paramsStr}`);

  async remove() {
    const { removeId } = this.state;
    const response = await deleteRequest(`contacto/${removeId}`);
    if (response.ok) {
      this.setState(
        { removeId: null },
        document.location.replace(`/contactos`)
      );
    } else {
      this.setState({ removeId: null, showSnackbar: true });
    }
  }

  parseData = contactos =>
    contactos.map(contacto => ({
      ...contacto,
      animales: contacto.animales.map(animal => ({
        id: animal.id,
        nombre: animal.nombre
      }))
    }));

  handleDeleteClick = removeId =>
    this.setState({ removeId, isDialogOpen: true });

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      removeId: null
    });
  };

  handleDialogAccept = () => {
    this.setState(
      {
        isDialogOpen: false
      },
      this.remove
    );
  };

  render() {
    return (
      <div className="wrapper">
        <AppNavbar />
        <Container fluid>
          <div className="add-button-wrapper">
            <Fab
              title="Nueva contacto"
              href="/contactos/new"
              className="add-button"
            >
              <AddIcon />
            </Fab>
          </div>
          <BaseTable
            title="Contactos"
            fetchData={this.fetchData}
            parseData={this.parseData}
            columnDefinitions={[
              { title: "Nombre", field: "nombre" },
              { title: "Apellido1", field: "apellido1" },
              { title: "Apellido2", field: "apellido2" },
              { title: "Telefono1", field: "telefono1" },
              { title: "Telefono2", field: "telefono2" },
              { title: "Email", field: "email" },
              { title: "DNI/NIE", field: "nif" },
              {
                title: "Animales",
                field: "animales",
                notSortable: true,
                render: rowData =>
                  rowData.animales.map((animal, index) => (
                    <p key={index}>
                      <a href={`/animal/${animal.id}`}>{animal.nombre}</a>
                    </p>
                  ))
              }
            ]}
            rowActions={[
              {
                icon: EditIcon,
                tooltip: "Editar contacto",
                onClick: row => document.location.replace(`/contacto/${row.id}`)
              },
              {
                icon: DeleteIcon,
                tooltip: "Eliminar contacto",
                onClick: row => this.handleDeleteClick(row.id)
              }
            ]}
          />
          <CustomDialog
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            onAccept={this.handleDialogAccept}
            message="Se va a eliminar el contacto. Esta acción no puede deshacerse. Confirmar acción?"
          />
        </Container>

        {this.state.showSnackbar && (
          <CustomSnackbar
            variant="error"
            message="No se puede eliminar un contacto que pertenece a una reserva o estadia"
          />
        )}
      </div>
    );
  }
}

export default Contactos;
