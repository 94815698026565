import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardContent from "@material-ui/core/CardContent";
import "../styles/form.mod.css";

class AnimalForm extends Component {
  static displayName = "AnimalForm";

  static propTypes = {
    values: PropTypes.shape({
      nombre: PropTypes.string,
      especie: PropTypes.string,
      tamanyo: PropTypes.string,
      sexo: PropTypes.string,
      chip: PropTypes.string,
      fechaNacimiento: PropTypes.string,
      contactos: PropTypes.array
    }).isRequired,
    name: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      touched,
      errors,
      name
    } = this.props;
    return (
      <CardContent className="card-content">
        <div className="form-line">
          <TextField
            required
            error={touched.nombre && errors.nombre}
            name={name ? `${name}.nombre` : "nombre"}
            label="Nombre"
            value={values.nombre}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
          <TextField
            required
            error={touched.especie && errors.especie}
            name={name ? `${name}.especie` : "especie"}
            label="Especie"
            value={values.especie}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
        </div>
        <div className="form-line">
          <TextField
            required
            error={touched.fechaNacimiento && errors.fechaNacimiento}
            name={name ? `${name}.fechaNacimiento` : "fechaNacimiento"}
            label="Fecha de nacimiento"
            type="date"
            defaultValue={values.fechaNacimiento}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            name={name ? `${name}.chip` : "chip"}
            label="Nº Chip"
            value={values.chip}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
          />
        </div>
        <div className="form-line">
          <FormControl
            required
            component="fieldset"
            className="radio-group-wrapper"
            error={touched.sexo && errors.sexo}
          >
            <FormLabel component="legend">Sexo</FormLabel>
            <RadioGroup
              aria-label="Sexo"
              name={name ? `${name}.sexo` : "sexo"}
              className="radio-group"
              value={values.sexo}
              onChange={handleChange}
            >
              <FormControlLabel
                value="hembra"
                control={<Radio />}
                label="Hembra"
              />
              <FormControlLabel
                value="macho"
                control={<Radio />}
                label="Macho"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="form-line">
          <FormControl
            required
            component="fieldset"
            className="radio-group-wrapper"
            error={touched.tamanyo && errors.tamanyo}
          >
            <FormLabel component="legend">Tamaño</FormLabel>
            <RadioGroup
              aria-label="Tamaño"
              name={name ? `${name}.tamanyo` : "tamanyo"}
              className="radio-group"
              value={values.tamanyo}
              onChange={handleChange}
            >
              <FormControlLabel
                value="pequeño"
                control={<Radio />}
                label="Pequeño"
              />
              <FormControlLabel
                value="mediano"
                control={<Radio />}
                label="Mediano"
              />
              <FormControlLabel
                value="grande"
                control={<Radio />}
                label="Grande"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </CardContent>
    );
  }
}

export default AnimalForm;
