import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Animales from "./animales";
import AnimalNew from "./animal-new";
import AnimalEdit from "./animal-edit";
import Contactos from "./contactos";
import ContactoNew from "./contacto-new";
import ContactoEdit from "./contacto-edit";
import Factura from "./factura";
import Facturas from "./facturas";
import Estadias from "./estadias";
import EstadiaNew from "./estadia-new";
import EstadiaEdit from "./estadia-edit";
import Reservas from "./reservas";
import ReservaEdit from "./reserva-edit";
import ReservaNew from "./reserva-new";
import Login from "./login";
import Register from "./register";
import { authenticationService } from "./utils/authentication-service";
import "./styles/app.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isLoading: true
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x =>
      this.setState({ currentUser: x }, this.setState({ isLoading: false }))
    );
  }

  logout() {
    authenticationService.logout();
    document.location.replace("/login");
  }

  render() {
    if (document.location.pathname.endsWith("register")) {
      console.log("URL for `Register`");
      return <Register />;
    }
    const { isLoading, currentUser } = this.state;

    if (isLoading) {
      console.log("URL for `Register`");
      return <CircularProgress />;
    }
    // if (!currentUser) {
    //   console.log("Sign in!");
    //   return <Login />;
    // }

    console.log("Let's render the APP!");

    return (
      <div className="app">
        <Router>
          {currentUser ? (
            <Switch>
              <Route path={`/`} exact={true} component={Reservas} />
              <Route
                path={`/animal/:id`}
                render={props => <AnimalEdit {...props} />}
              />
              <Route
                path={`/animales/new`}
                render={props => <AnimalNew {...props} />}
              />
              <Route
                path={`/animales`}
                render={props => <Animales {...props} />}
              />
              <Route
                path={`/contacto/:id`}
                render={props => <ContactoEdit {...props} />}
              />
              <Route
                path={`/contactos/new`}
                render={props => <ContactoNew {...props} />}
              />
              <Route
                path={`/contactos`}
                render={props => <Contactos {...props} />}
              />
              <Route
                path={`/estadia/:id`}
                render={props => <EstadiaEdit {...props} />}
              />
              <Route
                path={`/estadias/new/:reservaId`}
                render={props => <EstadiaNew {...props} />}
              />
              <Route
                path={`/estadias/new`}
                render={props => <EstadiaNew {...props} />}
              />
              <Route
                path={`/estadias`}
                render={props => <Estadias {...props} />}
              />
              <Route
                path={`/reserva/:id`}
                render={props => <ReservaEdit {...props} />}
              />
              <Route
                path={`/reservas/new`}
                render={props => <ReservaNew {...props} />}
              />
              <Route
                path={`/reservas`}
                render={props => <Reservas {...props} />}
              />
              <Route path={`/factura/:id`} component={Factura} />
              <Route
                path={`/facturas-preview/:id`}
                render={props => <Factura {...props} />}
              />
              <Route
                path={`/facturas`}
                render={props => <Facturas {...props} />}
              />
              <Redirect to={`/reservas`} />
            </Switch>
          ) : (
            <Switch>
              <Route path={`/login`} render={props => <Login {...props} />} />
              <Route
                path={`/nick`}
                render={() => (
                  <div>
                    <h1>HI NICK, THANKS FOR COMING</h1>
                  </div>
                )}
              />
              <Redirect to={`/login`} />
            </Switch>
          )}
        </Router>
      </div>
    );
  }
}

export default App;
