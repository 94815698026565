import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import { getRequest, putRequest } from "../utils/secure-request";
import "./factura.mod.css";
import { EMPRESA, BASE, BASE_COVERED } from "../constants";
import calcularTotalFactura from "../utils/calcular-total-factura";

class Factura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      diasEstadia: 0,
      precioPorDia: 0,
      contacto: {
        nombre: "",
        apellido1: "",
        apellido2: "",
        direccion1: "",
        direccion2: "",
        direccion3: "",
        nif: ""
      },
      animales: [
        {
          nombre: "",
          tamanyo: ""
        }
      ],
      iva: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const endpoint = this.props.match.url.includes("-preview")
      ? "estadia"
      : "factura";
    const facturaEstadia = await getRequest(
      `${endpoint}/${this.props.match.params.id}`
    );
    const diasEstadia =
      facturaEstadia.dias ||
      (new Date(facturaEstadia.fechaSalida) -
        new Date(facturaEstadia.fechaEntrada)) /
        86400000;
    const facturaProps = {
      estadia: facturaEstadia,
      ...facturaEstadia,
      diasEstadia,
      fecha: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).format(new Date(facturaEstadia.fecha || facturaEstadia.fechaSalida)),
      precioPorDia: facturaEstadia.precioDiaCentimos * 0.01,
      animales: Array.isArray(facturaEstadia.animales)
        ? facturaEstadia.animales
        : facturaEstadia.animales.split(","),
      contacto: facturaEstadia.contacto || {
        nombre: facturaEstadia.nombre,
        apellido1: facturaEstadia.apellido1,
        apellido2: facturaEstadia.apellido2,
        telefono1: facturaEstadia.telefono1,
        telefono2: facturaEstadia.telefono2,
        email: facturaEstadia.email,
        nif: facturaEstadia.nif,
        direccion1: facturaEstadia.direccion1,
        direccion2: facturaEstadia.direccion2,
        direccion3: facturaEstadia.direccion3
      },
      iva: facturaEstadia.porcentajeIva,
      id: this.props.match.params.id,
      servicios: facturaEstadia.servicios || [],
      total: calcularTotalFactura({ ...facturaEstadia, dias: diasEstadia })
    };

    this.setState(facturaProps);
  }

  handleSubmit() {
    const { estadia, diasEstadia, precioPorDia } = this.state;
    const { contacto, animales } = estadia;
    const parsedValues = {
      ...estadia,
      factura: {
        fecha: estadia.fechaSalida,
        nombre: contacto.nombre,
        apellido1: contacto.apellido1,
        apellido2: contacto.apellido2,
        telefono1: contacto.telefono1,
        telefono2: contacto.telefono2,
        email: contacto.email,
        nif: contacto.nif,
        direccion1: contacto.direccion1,
        direccion2: contacto.direccion2,
        direccion3: contacto.direccion3,
        animales: animales.map(an => an.nombre).join(","),
        dias: diasEstadia,
        precioDiaCentimos: precioPorDia * 100,
        porcentajeIva: estadia.porcentajeIva
      }
    };
    putRequest(`estadia/${estadia.id}`, JSON.stringify(parsedValues))
      .then(data => document.location.replace(`/factura/${data.factura.id}`))
      .catch(error => window.alert(JSON.stringify(error)));
  }

  render() {
    const {
      id,
      contacto,
      animales,
      diasEstadia,
      precioPorDia,
      iva: ivaPorcentaje,
      fecha,
      servicios,
      total
    } = this.state;

    if (!total) return <CircularProgress />;
    let contactoString = `${contacto.apellido1}${
      contacto.apellido2 ? ` ${contacto.apellido2}` : ""
    }, ${contacto.nombre}`;

    const subTotal = total / 1.21;
    const iva = total - subTotal;
    const precioDiaSinIva = precioPorDia / 1.21;

    const numero = this.props.match.url.includes("-preview")
      ? BASE_COVERED
      : `${BASE.substring(0, BASE.length - id.length)}${id}`;

    return (
      <div className="factura-wrapper">
        <div className="printControlls">
          {this.props.match.url.includes("-preview") ? (
            <Fab
              title="Guardar"
              className="print-button"
              onClick={this.handleSubmit}
            >
              <SaveIcon />
            </Fab>
          ) : (
            <Fab
              title="Imprimir"
              className="print-button"
              onClick={() => window.print()}
            >
              <PrintIcon />
            </Fab>
          )}
        </div>
        <div className="factura">
          <div className="header" />
          <div className="inner-wrapper">
            <div className="first-block">
              <div className="info-modepran">
                <table className="factura-table">
                  <tbody>
                    <tr>
                      <td>
                        <h1 className="title">{EMPRESA.NOMBRE}</h1>
                      </td>
                    </tr>
                    <tr>
                      <td>{EMPRESA.CIF}</td>
                    </tr>
                    <tr>
                      <td>{EMPRESA.DIRECCION1}</td>
                    </tr>
                    <tr>
                      <td>{EMPRESA.DIRECCION2}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="image">
                <img
                  alt="protectoraLogo"
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  width="150px"
                />
              </div>
            </div>
            <div className="second-block">
              <table className="factura-table">
                <tbody>
                  <tr>
                    <td colSpan="2">FACTURAR A</td>
                    <td colSpan="2">ENVIAR A</td>
                    <td>Nº FACTURA</td>
                    <td className="align-right">{numero}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">{contactoString}</td>
                    <td colSpan="2">{contacto.direccion1}</td>
                    <td>FECHA</td>
                    <td className="align-right">{fecha}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">{`NIF ${contacto.nif}`}</td>
                    <td colSpan="2">{contacto.direccion2}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" />
                    <td colSpan="2">{contacto.direccion3}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="third-block">
              <div className="separator" />
              <table className="factura-table">
                <tbody>
                  <tr className="total">
                    <td>Total Factura</td>
                    <td className="align-right">{`${total.toFixed(2)}€`}</td>
                  </tr>
                </tbody>
              </table>

              <div className="separator" />
            </div>

            <div className="forth-block">
              <table className="detalles">
                <tbody>
                  <tr>
                    <th className="align-left">DESCRIPCION</th>
                    <th className="align-left">Nº DIAS</th>
                    <th className="align-right">PRECIO DIARIO</th>
                    <th className="align-right">IMPORTE</th>
                  </tr>
                  {animales.map((animal, index) => (
                    <tr key={index}>
                      <td>{`Estadia de ${animal.nombre || animal}`}</td>
                      <td>{diasEstadia}</td>
                      <td className="align-right">{`${precioDiaSinIva.toFixed(
                        2
                      )}€`}</td>
                      <td className="align-right">{`${(
                        precioDiaSinIva * diasEstadia
                      ).toFixed(2)}€`}</td>
                    </tr>
                  ))}
                  {servicios.map((servicio, index) => (
                    <tr key={index}>
                      <td>{servicio.servicio}</td>
                      <td>-</td>
                      <td className="align-right">-</td>
                      <td className="align-right">{`${(
                        (servicio.precio * 0.01) /
                        1.21
                      ).toFixed(2)}€`}</td>
                    </tr>
                  ))}
                  <tr className="subtotal">
                    <td />
                    <td />
                    <td className="align-right">Subtotal</td>
                    <td className="align-right">{`${subTotal.toFixed(2)}€`}</td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td className="align-right">{`IVA ${ivaPorcentaje}%`}</td>
                    <td className="align-right">{`${iva.toFixed(2)}€`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="footer" />
        </div>
      </div>
    );
  }
}

export default Factura;
