import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class CustomDialog extends Component {
  static displayName = "CustomDialog";
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    acceptText: PropTypes.string,
    cancelText: PropTypes.string,
    type: PropTypes.oneOf(["yes", "yesNo", "none"])
  };

  static defaultProps = {
    acceptText: "Aceptar",
    cancelText: "Cancelar",
    type: "yesNo",
    title: ""
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.message}
          </DialogContentText>
        </DialogContent>
        {this.props.type !== "none" && (
          <DialogActions>
            {this.props.type === "yesNo" && (
              <Button onClick={this.props.onClose} color="primary">
                {this.props.cancelText}
              </Button>
            )}
            <Button onClick={this.props.onAccept} color="primary" autoFocus>
              {this.props.acceptText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default CustomDialog;
