import React, { Component } from "react";
import { Container } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import { getRequest } from "../utils/secure-request";
import AppNavbar from "../app-nav-bar";
import BaseTable from "../base-table";
import "../styles/tables.mod.css";

const hoy = new Date(Date.now());
const getContactoString = contacto => {
  const apellido2 =
    contacto.apellido2 && contacto.apellido2.length > 0
      ? ` ${contacto.apellido2}, `
      : ", ";
  return `${contacto.apellido1}${apellido2}${contacto.nombre}`;
};
class Animales extends Component {
  static displayName = "Animales";

  fetchData = paramsStr => getRequest(`animales?${paramsStr}`);

  calculateAge(nacimiento) {
    const fechaNacimiento = new Date(nacimiento);
    const dif = hoy - fechaNacimiento;
    const anyos = dif / 31104000000;
    const meses = (anyos - Math.floor(anyos)) * 12;
    if (anyos < 1) return `${Math.floor(meses)} meses`;
    return `${Math.floor(anyos)} años, ${Math.floor(meses)} meses`;
  }

  parseData = animales =>
    animales.map(animal => ({
      ...animal,
      edad: this.calculateAge(animal.fechaNacimiento)
    }));

  render() {
    return (
      <div className="wrapper">
        <AppNavbar />
        <Container fluid>
          <div className="add-button-wrapper">
            <Fab
              title="Nueva animale"
              href="/animales/new"
              className="add-button"
            >
              <AddIcon />
            </Fab>
          </div>
          <BaseTable
            title="Animales"
            fetchData={this.fetchData}
            parseData={this.parseData}
            columnDefinitions={[
              { title: "Nombre", field: "nombre" },
              { title: "Especie", field: "especie" },
              { title: "Tamaño", field: "tamanyo" },
              { title: "Sexo", field: "sexo" },
              { title: "Edad", field: "edad", notSortable: true },
              {
                title: "Contacto",
                field: "contacto",
                notSearchable: true,
                notSortable: true,
                render: rowData =>
                  rowData.contactos
                    ? rowData.contactos.map(contacto => (
                        <p key={contacto.id}>
                          <a href={`/contacto/${contacto.id}`}>
                            {getContactoString(contacto)}
                          </a>
                        </p>
                      ))
                    : null
              }
            ]}
            rowActions={[
              {
                icon: EditIcon,
                tooltip: "Editar animal",
                onClick: row => document.location.replace(`/animal/${row.id}`)
              }
            ]}
          />
        </Container>
      </div>
    );
  }
}

export default Animales;
