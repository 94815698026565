import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray, getIn } from "formik";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AnimalForm from "../animal-form";
import { emptyAnimal } from "../animal-new";
import "./animales-list-form.mod.css";

class AnimalesListForm extends Component {
  static propTypes = {
    values: PropTypes.shape({
      animales: PropTypes.arrayOf(
        PropTypes.shape({
          nombre: PropTypes.string,
          especie: PropTypes.string,
          tamanyo: PropTypes.string,
          sexo: PropTypes.string,
          chip: PropTypes.string,
          fechaNacimiento: PropTypes.string,
          idContacto: PropTypes.number
        })
      )
    }),
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };

  state = {
    expanded: 0
  };

  handleExpand = expanded =>
    this.setState(prev => ({
      expanded: prev.expanded === expanded ? null : expanded
    }));

  handleAdd = (push, form) => {
    this.setState({ expanded: form.values.animales.length }, push(emptyAnimal));
  };

  handleRemove = (remove, index) => {
    console.log({ index });
    this.setState({ expanded: null }, remove(index));
  };

  render() {
    const { values, handleChange, handleBlur, touched, errors } = this.props;
    const { expanded } = this.state;
    return (
      <FieldArray
        name="animales"
        render={({ push, remove, form }) => (
          <div className="animales-list-form-wrapper">
            {values.animales.map((animal, index) => {
              const animalErrors = getIn(errors, `animales.${index}`, {});
              const animalTouched = getIn(touched, `animales.${index}`, {});
              return (
                <ExpansionPanel
                  key={`animal-panel-${index}`}
                  expanded={expanded === index}
                  onChange={() => this.handleExpand(index)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`animal-panel-${index}-content`}
                    id={`animal-panel-${index}-header`}
                  >
                    <Typography>
                      {`${index + 1} - ${animal.nombre || "..."}`}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="animales-list-expansion-details">
                    <AnimalForm
                      key={`animal-${index}`}
                      name={`animales.${index}`}
                      values={animal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={animalTouched}
                      errors={animalErrors}
                    />
                    <div className="animales-list-remove-item">
                      <IconButton
                        onClick={() => this.handleRemove(remove, index)}
                        size="small"
                        disabled={values.animales.length < 2}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
            <div className="animales-list-form-add-button">
              <Button
                disabled={!form.isValid}
                variant="outlined"
                onClick={() => this.handleAdd(push, form)}
              >
                <AddIcon />
              </Button>
            </div>
          </div>
        )}
      />
    );
  }
}

export default AnimalesListForm;
