import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import { postRequest } from "../utils/secure-request";
import ContactoForm from "../contacto-form";
import AnimalesListForm from "../animales-list-form";
import AppNavbar from "../app-nav-bar";
import { emptyAnimal } from "../animal-new";
import "../styles/app.css";
import "../styles/form.mod.css";

const emptyContacto = {
  nombre: "",
  apellido1: "",
  apellido2: "",
  telefono1: "",
  telefono2: "",
  email: "",
  nif: "",
  direccion1: "",
  direccion2: "",
  direccion3: "",
  animales: [emptyAnimal]
};

const validateAnimal = values => {
  const errors = {};
  if (values.nombre.length === 0) errors.nombre = true;
  if (values.especie.length === 0) errors.especie = true;
  if (values.tamanyo.length === 0) errors.tamanyo = true;
  if (values.sexo.length === 0) errors.sexo = true;
  if (values.fechaNacimiento.length === 0) errors.fechaNacimiento = true;
  return errors;
};

const validateContacto = values => {
  const errors = {};
  if (values.nif.length === 0) errors.nif = true;
  if (values.nombre.length === 0) errors.nombre = true;
  if (values.apellido1.length === 0) errors.apellido1 = true;
  if (values.telefono1.length === 0) errors.telefono1 = true;
  if (values.direccion1.length === 0) errors.direccion1 = true;
  return errors;
};

const validate = values => {
  const contactoErrors = validateContacto(values);

  if (values.animales && values.animales.length > 0) {
    const animales = [];
    values.animales.forEach((animal, index) => {
      const animalErrors = validateAnimal(animal);
      animalErrors.push(animalErrors);
    });
    if (animales.length > 0) contactoErrors.animales = animales;
  }

  return contactoErrors;
};

class ContactoNew extends Component {
  static displayName = "ContactoNew";
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      steps: ["Datos del contacto", "Animales del contacto"]
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    postRequest("contacto", JSON.stringify(values))
      .then(data => document.location.replace(`/contacto/${data.id}`))
      .catch(error => window.alert(JSON.stringify(error)));
  }

  handleCancel = values => {
    this.props.history.goBack();
  };

  handleNext = (values, setFieldTouched) => {
    if (Object.keys(validateContacto(values)).length > 0) {
      setFieldTouched("nif", true);
      setFieldTouched("nombre", true);
      setFieldTouched("apellido1", true);
      setFieldTouched("telefono1", true);
      setFieldTouched("direccion1", true);
    } else {
      this.setState(prev => ({ activeStep: prev.activeStep + 1 }));
    }
  };

  handleBack = () =>
    this.setState(prev => ({ activeStep: prev.activeStep - 1 }));

  render() {
    const { activeStep, steps } = this.state;
    return (
      <div className="app">
        <AppNavbar />
        <div className="form-wrapper">
          <Formik
            initialValues={emptyContacto}
            onSubmit={this.handleSubmit}
            validate={values => validate(values)}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched
              } = props;
              return (
                <Card>
                  <CardHeader
                    title={
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          return (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    }
                  />
                  {activeStep === 0 && (
                    <ContactoForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  )}
                  {activeStep === 1 && (
                    <AnimalesListForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  )}
                  <CardActions>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className="stepper-button"
                    >
                      Atras
                    </Button>

                    {activeStep === 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleNext(values, setFieldTouched)}
                        className="stepper-button"
                      >
                        Siguiente
                      </Button>
                    )}
                    {activeStep === 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !dirty}
                        onClick={handleSubmit}
                        className="stepper-button"
                      >
                        Guardar
                      </Button>
                    )}
                  </CardActions>
                </Card>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default ContactoNew;
