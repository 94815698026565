import React, { Component } from "react";
import PropTypes from "prop-types";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { getRequest } from "../utils/secure-request";

class ContactoSearch extends Component {
  static displayName = "ContactoSearch";
  static propTypes = {
    onAddContacto: PropTypes.func.isRequired,
    hasError: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      contactos: [],
      searchBarReference: React.createRef(),
      popperReference: null,
      isNoContactosOpen: false
    };
    this.handleContactoSearch = this.handleContactoSearch.bind(this);
  }

  emptyContactoSearch = contactoSearch =>
    this.setState({ contactoSearch, contacto: null });

  setSelectedContacto = contacto => {
    const contactoSearch = `${contacto.nombre} ${contacto.apellido1} ${contacto.apellido2}`;
    this.setState({
      contacto,
      popperReference: null,
      contactoSearch
    });
    if (this.props.onAddContacto) {
      this.props.onAddContacto(contacto);
      this.emptyContactoSearch("");
    }
  };

  async handleContactoSearch() {
    const contactos = await getRequest(
      `searchcontactos?searchValues=${this.state.contactoSearch
        .split(" ")
        .join(",")}`
    );
    if (contactos.length > 0) {
      this.setState({
        contactos,
        popperReference: this.state.searchBarReference.current
      });
    } else {
      this.setState({ isNoContactosOpen: true });
    }
  }

  render() {
    return (
      <div className="inline-row">
        <div className="inline-row">
          <TextField
            ref={this.state.searchBarReference}
            label="Añadir Contacto"
            type="search"
            className="search"
            margin="normal"
            error={this.props.hasError || false}
            onChange={event =>
              this.setState({ contactoSearch: event.target.value })
            }
            value={this.state.contactoSearch}
            fullWidth
            InputProps={{
              shrink: "true",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Buscar"
                    onClick={() => this.emptyContactoSearch("")}
                  >
                    <HighlightOffIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="Buscar"
                    onClick={this.handleContactoSearch}
                  >
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Popper
            id="select-contacto-edit-animal"
            open={this.state.popperReference ? true : false}
            anchorEl={this.state.popperReference}
            className="search"
            placement="bottom-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  {this.state.contactos.map(contacto => (
                    <MenuItem
                      key={contacto.id}
                      value={contacto.id}
                      onClick={() => this.setSelectedContacto(contacto)}
                    >
                      {`${contacto.nombre} ${contacto.apellido1} ${contacto.apellido2}`}
                    </MenuItem>
                  ))}
                </Paper>
              </Fade>
            )}
          </Popper>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={this.state.isNoContactosOpen}
            autoHideDuration={5000}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            onClose={() => this.setState({ isNoContactosOpen: false })}
            message={<span id="message-id">No se encontraron contactos</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Cerrar"
                color="inherit"
                onClick={() => this.setState({ isNoContactosOpen: false })}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </div>
        <div>
          <IconButton
            onClick={() => {
              this.props.onAddContacto(this.state.contacto);
              this.emptyContactoSearch("");
            }}
            size="small"
            disabled={!this.state.contacto}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default ContactoSearch;
