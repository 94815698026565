import { authenticationService } from "./authentication-service";

const API_URL =
  process.env.NODE_ENV === "production" ? "https://protectoramodepran.com" : "";

export async function getRequest(url) {
  const currentUser = authenticationService.currentUserValue;
  return await fetch(`${API_URL}/hotel/api/${url}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentUser.token}`
    }
  }).then(response => {
    if (response.ok) return response.json();

    authenticationService.logout();
    document.location.replace(`/login`);
  });
}

export async function postRequest(url, body) {
  const currentUser = authenticationService.currentUserValue;
  return await fetch(`${API_URL}/hotel/api/${url}`, {
    method: "POST",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentUser.token}`
    }
  }).then(response => {
    if (response.ok) return response.json();

    authenticationService.logout();
    document.location.replace(`/login`);
  });
}

export async function putRequest(url, body) {
  const currentUser = authenticationService.currentUserValue;
  return await fetch(`${API_URL}/hotel/api/${url}`, {
    method: "PUT",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentUser.token}`
    }
  }).then(response => {
    if (response.ok) return response.json();

    authenticationService.logout();
    document.location.replace(`/login`);
  });
}

export async function deleteRequest(url) {
  const currentUser = authenticationService.currentUserValue;
  return await fetch(`${API_URL}/hotel/api/${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${currentUser.token}`
    }
  }).then(response => {
    if (response.ok) return response.json();

    authenticationService.logout();
    document.location.replace(`/login`);
  });
}
