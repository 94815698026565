import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import ContactoSearch from "../contacto-search";
import "../styles/form.mod.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class ReservaContactoSubform extends Component {
  static displayName = "ReservaContactoSubform";
  static propTypes = {
    formikProps: PropTypes.shape({
      values: PropTypes.shape({
        contacto: PropTypes.shape({
          nombre: PropTypes.string,
          apellido1: PropTypes.string,
          apellido2: PropTypes.string,
          animales: PropTypes.array
        }),
        animales: PropTypes.array
      }),
      handleChange: PropTypes.func.isRequired,
      handleBlur: PropTypes.func.isRequired,
      touched: PropTypes.object.isRequired,
      errors: PropTypes.object.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      contactos: [],
      searchBarReference: React.createRef(),
      popperReference: null,
      isNoContactosOpen: false
    };
  }

  render() {
    const {
      values,
      touched,
      errors,
      setFieldValue,
      setFieldTouched
    } = this.props.formikProps;
    return (
      <CardContent className="card-content">
        {!values.contacto && (
          <ContactoSearch
            onAddContacto={contacto => {
              setFieldValue("contacto", contacto);
              setFieldTouched("contacto", true);
            }}
            hasError={errors.contacto && touched.contacto}
          />
        )}
        {values.contacto && (
          <div className="contacto-form-line">
            <TextField
              disabled
              className="search"
              label={"Contacto"}
              value={`${values.contacto.nombre} ${values.contacto.apellido1} ${values.contacto.apellido2}`}
              fullWidth
            />
            <IconButton
              onClick={() => {
                setFieldValue("contacto", null);
                setFieldValue("animales", null);
              }}
              size="small"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        {values.contacto && values.contacto.animales && (
          <div className="contacto-form-line">
            <FormControl className="multi-select">
              <InputLabel htmlFor="select-multiple-animales">
                Animales
              </InputLabel>
              <Select
                multiple
                name="animales"
                value={values.animales.map(animal => animal.id) || []}
                onChange={({ target }) => {
                  const animales = values.contacto.animales.filter(animal =>
                    target.value.includes(animal.id)
                  );
                  setFieldValue("animales", animales);
                  setFieldTouched("animales", true);
                }}
                error={(errors.animales && touched.animales) || false}
                input={<Input id="select-multiple-animales" />}
                renderValue={selected => (
                  <div className="chips">
                    {selected.map(value => {
                      const animal = values.contacto.animales.find(
                        animal => animal.id === value
                      );
                      return (
                        <Chip
                          key={animal.id}
                          label={animal.nombre}
                          className="chip"
                        />
                      );
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {values.contacto.animales.map(animal => (
                  <MenuItem key={animal.id} value={animal.id}>
                    {animal.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </CardContent>
    );
  }
}

export default ReservaContactoSubform;
