import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { authenticationService } from "../utils/authentication-service";
import "./app-nav-bar.mod.css";

function SimpleAppBar() {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const screens = [
    { label: "Reservas", link: "/reservas" },
    { label: "Estadias", link: "/estadias" },
    { label: "Animales", link: "/animales" },
    { label: "Contactos", link: "/contactos" },
    { label: "Facturas", link: "/facturas" }
  ];
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
          <div className="toolbar">
            {screens.map(({ label, link }, index) => (
              <Button key={index} href={link} variant="text">
                <span className="links">{label}</span>
              </Button>
            ))}
            <IconButton
              key="logout"
              aria-label="Salir"
              color="inherit"
              onClick={() => {
                authenticationService.logout();
                document.location.replace("login");
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </div>
          <div className="drawer-button">
            <IconButton
              key="menu"
              aria-label="Menu"
              color="inherit"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <List>
            {screens.map(({ link, label }, index) => (
              <ListItem button component="a" href={link} key={index}>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
          <IconButton
            key="logout"
            aria-label="Salir"
            color="inherit"
            onClick={() => {
              authenticationService.logout();
              document.location.replace("login");
            }}
          >
            <ExitToAppIcon />
          </IconButton>
        </Drawer>
      </AppBar>
    </div>
  );
}

export default SimpleAppBar;
