import React, { Component } from "react";
import { Container } from "reactstrap";
import AddIcon from "@material-ui/icons/Add";
import PortraitIcon from "@material-ui/icons/Portrait";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { getRequest, deleteRequest } from "../utils/secure-request";
import AppNavbar from "../app-nav-bar";
import BaseTable from "../base-table";
import CustomDialog from "../custom-dialog";
import CustomSnackbar from "../custom-snackbar";
import "../styles/tables.mod.css";

class Estadias extends Component {
  static displayName = "Estadias";
  constructor(props) {
    super(props);
    this.state = {
      isFacturaOpen: false,
      facturaEstadia: {},
      isDialogOpen: false,
      removeId: null,
      showSnackbar: false
    };
    this.remove = this.remove.bind(this);
  }

  fetchData = paramsStr => getRequest(`estadias?${paramsStr}`);

  async remove() {
    const { removeId } = this.state;
    const response = await deleteRequest(`estadia/${removeId}`);
    if (response.ok) {
      this.setState({ removeId: null }, document.location.replace(`/estadias`));
    } else {
      this.setState({ removeId: null, showSnackbar: true });
    }
  }

  handleOpenFactura(facturaId) {
    const win = window.open(`/factura/${facturaId}`, "_blank");
    win.focus();
  }

  handleOpenPreviewFactura(estadiaId) {
    const win = window.open(`/facturas-preview/${estadiaId}`, "_blank");
    win.focus();
  }

  parseData = estadias =>
    estadias.map(estadia => ({
      id: estadia.id,
      fechaEntrada: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(new Date(estadia.fechaEntrada)),
      horaEntrada: estadia.horaEntrada,
      fechaSalida: new Intl.DateTimeFormat("es-ES", {
        year: "numeric",
        month: "long",
        day: "2-digit"
      }).format(new Date(estadia.fechaSalida)),
      horaSalida: estadia.horaSalida,
      contacto: `${estadia.contacto.apellido1}, ${estadia.contacto.nombre}`,
      contactoId: estadia.contacto.id,
      telefono1: estadia.contacto.telefono1,
      telefono2: estadia.contacto.telefono2,
      precioDiaCentimos: `${(estadia.precioDiaCentimos * 0.01).toFixed(2)}€`,
      animales: estadia.animales.map(
        animal => `${animal.nombre} (${animal.especie}, ${animal.sexo})`
      ),
      animalesIds: estadia.animales.map(animal => animal.id),
      factura: estadia.factura
    }));

  handleDeleteClick = removeId =>
    this.setState({ removeId, isDialogOpen: true });

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      removeId: null
    });
  };

  handleDialogAccept = () => {
    this.setState(
      {
        isDialogOpen: false
      },
      this.remove
    );
  };

  render() {
    return (
      <div className="wrapper">
        <AppNavbar />
        <Container fluid>
          <div className="add-button-wrapper">
            <Fab
              title="Nueva estadia"
              href="/estadias/new"
              className="add-button"
            >
              <AddIcon />
            </Fab>
          </div>
          <BaseTable
            title="Estadias"
            fetchData={this.fetchData}
            parseData={this.parseData}
            columnDefinitions={[
              {
                title: "Entrada",
                field: "fechaEntrada",
                searchable: false
              },
              {
                title: "Hora",
                field: "horaEntrada"
              },
              {
                title: "Salida",
                field: "fechaSalida",
                searchable: false
              },
              { title: "Hora", field: "horaSalida" },
              {
                title: "Precio",
                field: "precioDiaCentimos",
                searchable: false
              },
              {
                title: "Contacto",
                field: "contacto",
                render: rowData => (
                  <div>
                    <Button
                      title="Contacto"
                      href={`/contacto/${rowData.contactoId}`}
                      className="contact-button"
                    >
                      <PortraitIcon />
                    </Button>
                    {rowData.contacto}
                  </div>
                )
              },
              {
                title: "Telefono1",
                field: "telefono1"
              },
              {
                title: "Telefono2",
                field: "telefono2"
              },
              {
                title: "Animales",
                field: "animales",
                render: rowData =>
                  rowData.animales.map((animal, index) => (
                    <p key={index}>
                      <a href={`/animal/${rowData.animalesIds[index]}`}>
                        {animal}
                      </a>
                    </p>
                  ))
              }
            ]}
            rowActions={[
              {
                icon: EditIcon,
                tooltip: "Editar estadia",
                onClick: row => document.location.replace(`/estadia/${row.id}`),
                show: row => !row.factura
              },
              {
                icon: DeleteIcon,
                tooltip: "Eliminar estadia",
                onClick: row => this.handleDeleteClick(row.id),
                show: row => !row.factura
              },
              {
                icon: RemoveRedEyeIcon,
                tooltip: "Previsualizar",
                onClick: row => this.handleOpenPreviewFactura(row.id),
                show: row => !row.factura
              },
              {
                icon: InsertDriveFileIcon,
                tooltip: "Factura",
                onClick: row => this.handleOpenFactura(row.factura.id),
                show: row => row.factura
              }
            ]}
          />
          <CustomDialog
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            onAccept={this.handleDialogAccept}
            message="Se va a eliminar la estadia. Esta acción no puede deshacerse. Confirmar acción?"
          />
          {this.state.showSnackbar && (
            <CustomSnackbar
              variant="error"
              message="No se pudo eliminar la estadia"
            />
          )}
        </Container>
      </div>
    );
  }
}

export default Estadias;
