import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getRequest, putRequest } from "../utils/secure-request";
import ContactoForm from "../contacto-form";
import AppNavbar from "../app-nav-bar";
import "../styles/app.css";
import "../styles/form.mod.css";

const validate = values => {
  const errors = {};
  if (values.nif.length === 0) errors.nif = true;
  if (values.nombre.length === 0) errors.nombre = true;
  if (values.apellido1.length === 0) errors.apellido1 = true;
  if (values.telefono1.length === 0) errors.telefono1 = true;
  if (values.direccion1.length === 0) errors.direccion1 = true;
  return errors;
};

class ContactoEdit extends Component {
  static displayName = "ContactoEdit";
  static propTypes = {
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      contactoId: props.match.params.id,
      contacto: null,
      isLoading: true,
      searchBarReference: React.createRef(),
      popperReference: null,
      isNoAnimalOpen: false,
      isSuccessOpen: false
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAnimalSearch = this.handleAnimalSearch.bind(this);
  }

  async componentDidMount() {
    const contacto = await getRequest(`contacto/${this.props.match.params.id}`);

    this.setState({
      isLoading: false,
      contacto
    });
  }

  handleSubmit(values, addHandlers) {
    addHandlers(
      putRequest(`contacto/${this.state.contactoId}`, JSON.stringify(values)),
      this.setState({ isSuccessOpen: true })
    );
  }

  async handleAnimalSearch() {
    const animales = await getRequest(
      `searchanimales?nombre=${this.state.animalSearch}`
    );
    if (animales.length > 0) {
      this.setState({
        animales,
        popperReference: this.state.searchBarReference.current
      });
    } else {
      this.setState({ isNoAnimalOpen: true });
    }
  }

  handleCancel = () => {
    document.location.replace(`/contactos`);
  };

  renderAnimalSearch = push => {
    return (
      <div className="inline-row">
        <TextField
          ref={this.state.searchBarReference}
          label="Añadir Animal"
          type="search"
          className="search"
          margin="normal"
          onChange={event =>
            this.setState({ animalSearch: event.target.value })
          }
          value={this.state.animalSearch}
          fullWidth
          InputProps={{
            shrink: "true",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Buscar"
                  onClick={() => this.emptyAnimalSearch("")}
                >
                  <HighlightOffIcon fontSize="small" />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="Buscar"
                  onClick={this.handleAnimalSearch}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Popper
          id="select-contacto-edit-animal"
          open={this.state.popperReference ? true : false}
          anchorEl={this.state.popperReference}
          className="search"
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                {this.state.animales.map(animal => (
                  <MenuItem
                    key={animal.id}
                    value={animal.id}
                    onClick={() => this.setSelectedAnimal(animal)}
                  >
                    {`${animal.nombre} (${animal.especie} ${animal.sexo})`}
                  </MenuItem>
                ))}
              </Paper>
            </Fade>
          )}
        </Popper>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.isNoAnimalOpen}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          onClose={() => this.setState({ isNoAnimalOpen: false })}
          message={<span id="message-id">No se encontraron animales</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Cerrar"
              color="inherit"
              onClick={() => this.setState({ isNoAnimalOpen: false })}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  };

  emptyAnimalSearch = animalSearch =>
    this.setState({ animalSearch, animal: null });

  setSelectedAnimal = animal => {
    const animalSearch = `${animal.nombre} (${animal.especie} ${animal.sexo})`;
    this.setState({
      animal,
      popperReference: null,
      animalSearch
    });
  };

  render() {
    return (
      <div className="app">
        <AppNavbar />
        <div className="form-wrapper">
          {this.state.isLoading ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={this.state.contacto}
              onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                  promise.then(
                    result => {
                      formikBag.setSubmitting(false);
                      return result;
                    },
                    error => {
                      formikBag.setSubmitting(false);
                      throw error;
                    }
                  );

                return this.handleSubmit(values, addHandlers);
              }}
              validate={values => validate(values)}
            >
              {formikProps => {
                return (
                  <Card>
                    <div className="form-title">
                      <CardHeader title="Editar Contacto" />
                    </div>
                    <ContactoForm
                      values={formikProps.values}
                      handleChange={formikProps.handleChange}
                      handleBlur={formikProps.handleBlur}
                      touched={formikProps.touched}
                      errors={formikProps.errors}
                    />

                    <FieldArray
                      name="animales"
                      render={({ push, remove, form }) => (
                        <CardContent>
                          {formikProps.values.animales.map((animal, index) => (
                            <div
                              key={`animal-${index}`}
                              className="contacto-form-line"
                            >
                              <TextField
                                disabled
                                className="search"
                                label={`Animal ${index + 1}`}
                                value={`${animal.nombre} (${animal.especie} ${animal.sexo})`}
                                fullWidth
                              />
                              <IconButton
                                onClick={() => remove(index)}
                                size="small"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          ))}
                          <div className="inline-row">
                            {this.renderAnimalSearch()}
                            <div>
                              <IconButton
                                onClick={() => {
                                  push(this.state.animal);
                                  this.emptyAnimalSearch("");
                                }}
                                size="small"
                                disabled={!this.state.animal}
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </div>
                        </CardContent>
                      )}
                    />
                    <CardActions>
                      <div className="inline-row">
                        <Button
                          disabled={formikProps.isSubmitting}
                          onClick={this.handleCancel}
                          size="small"
                        >
                          Atras
                        </Button>
                        <div className="actions-row">
                          <Button
                            disabled={formikProps.isSubmitting}
                            onClick={formikProps.handleReset}
                            size="small"
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={
                              formikProps.isSubmitting || !formikProps.dirty
                            }
                            onClick={formikProps.handleSubmit}
                            size="small"
                          >
                            Guardar
                          </Button>
                        </div>
                      </div>
                    </CardActions>
                  </Card>
                );
              }}
            </Formik>
          )}
        </div>
        <Snackbar
          className="success-message"
          aria-describedby="success-message"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.isSuccessOpen}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          onClose={() => this.setState({ isSuccessOpen: false })}
          message={
            <span id="success-message" className="message">
              <CheckCircleIcon className="icon" /> Cambios guardados
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Cerrar"
              color="inherit"
              onClick={() => this.setState({ isSuccessOpen: false })}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default ContactoEdit;
